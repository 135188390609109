import Link from 'next/link';
import { withRouter } from 'next/router';
import React, { Component } from 'react';

import { btnSnsBlog, btnSnsCafe } from '../../constants/images';
import styles from './MobileFooterStyle.module.scss';

class MobileFooter extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    if (this.props.router.pathname === '/_error') {
      return null;
    }

    return (
      <div className={styles.mobile_footer_container}>
        <div className={styles.mobile_footer_area}>
          <div className={styles.mobile_footer_term_area}>
            <Link href="/serviceTerms/termOfService">
              <div className={styles.mobile_footer_term}>{MESSAGE.USE_TERM}</div>
            </Link>
            <Link href="/serviceTerms/termOfLocationService">
              <div className={styles.mobile_footer_term}>{MESSAGE.LOCATION_SERVICE_TERM}</div>
            </Link>
            <Link href="/serviceTerms/privacyTreat">
              <div className={styles.mobile_footer_term}>{MESSAGE.PRIVACY_DATA_TERM}</div>
            </Link>
            <Link href="/serviceTerms/termForCustomer">
              <div className={styles.mobile_footer_term}>{MESSAGE.CUSTOMER_TERM}</div>
            </Link>
          </div>
          <div className={styles.mobile_footer_line} />
          <div className={styles.mobile_footer_company_info_area}>
            <div className={styles.mobile_footer_company_info_content}>
              {MESSAGE.RENCAR_COMPANY}
            </div>
            <div className={styles.mobile_footer_company_info_content}>
              {MESSAGE.RENCAR_ADDRESS}
            </div>
            <div className={styles.mobile_footer_company_info_content}>
              {MESSAGE.RENCAR_BOSS_NAME} | {MESSAGE.BUSSINESS_LICENSE_NUMBER}
            </div>
            <div className={styles.mobile_footer_company_info_content}>{MESSAGE.SALE_NUMBER}</div>
            <div className={styles.mobile_footer_company_info_content}>CS 1600-8348 (10AM-6PM)</div>
            <div className={styles.mobile_footer_company_info_content}>{MESSAGE.COPY_RIGHT}</div>
            <div className={styles.mobile_footer_company_link}>
              <a target="_blank" href="https://blog.naver.com/rencars">
                <img src={btnSnsBlog} />
              </a>
              <a target="_blank" href="https://cafe.naver.com/imsform">
                <img src={btnSnsCafe} />
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(MobileFooter);
